"use client";

import { Button } from "@/components/core-components";
import { STATIC_CONSTANTS } from "@/utils";
import Image from "next/image";
import React, { useState } from "react";

import copy from "@media/icons/icon-copy.png";
import facebook from "@media/icons/facebook.png";
import whatsapp from "@media/icons/whatsapp.png";
import instagram from "@media/icons/instagram.png";
import mail from "@media/icons/mail.png";
import { useAppSelector } from "@/store";

const ShareModal = ({
  modal,
  setModal,
  slug,
}: {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  slug?: string;
}) => {
  const { siteConfig } = useAppSelector((state) => state.common);
  const [copied, setCopied] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(`${siteConfig?.site_url}/${slug}` || "")
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      });
  };

  if (!modal) return null;
  return (
    <div className="modalOverlay">
      <div className="modalContent">
        <h5>Share this product</h5>
        <div className="buttonContainer flex-row mb-20">
          <Button
            type="button"
            textClassName={"d-flex"}
            onClick={handleCopyLink}
            className="shareButton mr-10"
            text={
              <React.Fragment>
                <Image
                  width={20}
                  height={20}
                  src={copy}
                  alt="copy"
                  className="icon"
                />
                {copied ? "Copied!" : "Copy Link"}
              </React.Fragment>
            }
          />
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              `${siteConfig?.site_url}/${slug}`
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <Image
              width={20}
              height={20}
              src={facebook}
              alt="share-facebook"
              className="icon"
            />
          </a>
          <a
            href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
              `${siteConfig?.site_url}/${slug}`
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <Image
              width={20}
              height={20}
              src={whatsapp}
              alt="share-whatsapp"
              className="icon"
            />
          </a>
          <a
            href={`https://www.instagram.com/?url=${encodeURIComponent(
              `${siteConfig?.site_url}/${slug}`
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <Image
              width={20}
              height={20}
              src={instagram}
              alt="share-instagram"
              className="icon"
            />
          </a>
          <a
            href={`mailto:?subject=Check out this product&body=${encodeURIComponent(
              `${siteConfig?.site_url}/${slug}`
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <Image
              width={20}
              height={20}
              src={mail}
              alt="share-email"
              className="icon"
            />
          </a>
        </div>
        <Button
          type="button"
          onClick={() => {
            setModal(false);
          }}
          className="btn btn-md"
          text={"Close"}
        />
      </div>
    </div>
  );
};

export { ShareModal };
