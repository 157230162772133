import {
  REQUEST_CONTENT_TYPE,
  REQUEST_METHOD,
  STATIC_CONSTANTS,
} from "./constants";
import {
  IDBMenu,
  IDBProduct,
  IFAQ,
  IProductCMSDetails,
  TProductDetails,
  IReview,
  IBannerContent,
  ICartItem,
  IDiscountData,
  RazorpayOptionsResponse,
  RazorpayOptionsPaymentDetails,
  SiteConfig,
  IStores,
  TStructuredData,
} from "./types";
import * as Sentry from "@sentry/nextjs";
import { AppDispatch, setPlacedOrderId } from "@/store";

export const validURL = (url: string) => {
  if (url.startsWith("_next/")) {
    return false;
  }

  return true;
};

export const isValidString = (str: string): boolean => {
  // Check if the string contains only alphabetic characters, spaces, or other allowed patterns
  const regex = /^[a-zA-Z0-9\s]*$/;
  return regex.test(str);
};

export const constructSlug = (slugs: string[]) => {
  let finalSlug = "";
  slugs.forEach((slug, index) => {
    if (index === 0) {
      finalSlug = slug;
    } else {
      finalSlug = `${finalSlug}/${slug}`;
    }
  });
  return finalSlug;
};

export const constructUOM = (uom: string) => (uom === "gms" ? "g" : `${uom}`);

export const formatProductForOrder = (item: IProductCMSDetails) => {
  if (!item) {
    throw new Error("Product details are missing.");
  }

  const {
    id,
    category,
    sub_category,
    product_name,
    product,
    product_weights,
    product_medias,
  } = item;

  const categoryName = category.display_name;
  const subCategoryName = sub_category.display_name;

  const slug = constructSlug([
    category.meta_slug,
    sub_category.meta_slug,
    item.meta_slug,
  ]);

  const weights = product_weights
    .map((weight) => ({
      id: weight.id,
      value: weight.weight,
      label: weight.weight,
      uom: constructUOM(product?.uom?.uom_code),
    }))
    .sort((a, b) => a.value - b.value);

  const formatMedia = (mediaType: string) =>
    product_medias
      .filter((media) => media.media_type === mediaType)
      .map((media) => ({
        alt: media.alt,
        img: media.media_file,
      }))
      .sort((a: any, b: any) => a.priority - b.priority);

  const images = formatMedia("full");
  const thumbnails = formatMedia("thumbnail");

  return {
    id,
    product_code: product.product_code,
    category: categoryName,
    sub_category: subCategoryName,
    slug,
    title: product_name,
    jain_friendly: product.jain_friendly,
    uom: constructUOM(product?.uom?.uom_code),
    weights,
    rating: 5,
    images,
    thumbnails,
  };
};

export const formatProduct = (item: IDBProduct) => {
  if (!item) {
    throw new Error("Something went wrong while fetching product");
  }

  const {
    id,
    category,
    sub_category,
    product,
    product_name,
    description,
    disclaimer,
    product_weights,
    wishlist_added,
    product_price,
    product_medias,
    created_at,
    updated_at,
    meta_title,
    meta_description,
    meta_keywords,
  } = item;

  const categoryName = category.display_name;
  const subCategoryName = sub_category.display_name;

  const slug = constructSlug([
    category.meta_slug,
    sub_category.meta_slug,
    item.meta_slug,
  ]);

  const weights = product_weights
    .map((weight) => ({
      id: weight.id,
      value: weight.weight,
      label: weight.weight,
      uom: constructUOM(product?.uom?.uom_code),
    }))
    .sort((a, b) => a.value - b.value);

  const discountType =
    product_price?.selling_discount_type === 4 ? "percentage" : "flat";

  const price = product_price?.selling_price || 0;
  const oldPrice = product_price?.mrp > price ? product_price?.mrp || 0 : 0;

  const taxRate = (type: number) =>
    Number(
      product?.product_tax?.find((tax) => tax.tax_type === type)?.tax_rate || 0
    );

  const formatMedia = (mediaType: string) =>
    product_medias
      .filter((media) => media.media_type === mediaType)
      .map((media) => ({
        alt: media.alt,
        img: media.media_file,
      }))
      .sort((a: any, b: any) => a.priority - b.priority);

  const images = formatMedia("full");
  const thumbnails = formatMedia("thumbnail");

  return {
    id,
    category: categoryName,
    sub_category: subCategoryName,
    slug,
    product,
    title: product_name,
    jain_friendly: product.jain_friendly,
    tastes: product?.taste_details?.map((taste) => taste.taste.taste_name),
    description,
    disclaimer,
    weights,
    rating: 5,
    wishlist_added,
    batch_id: product_price?.batch,
    price,
    oldPrice,
    discount: {
      type: product_price ? discountType : "",
      value: product_price?.selling_discount || 0,
    },
    product_id: product.id,
    product_code: product.product_code,
    shelf_life: product.expiration_days,
    net_weight: Number(product.net_weight),
    uom: constructUOM(product?.uom?.uom_code),
    selling_uom: product?.selling_uom?.uom_code,
    tax: taxRate(1),
    cess: taxRate(2),
    taxes: product.product_tax,
    images,
    thumbnails,
    meta_title,
    meta_description,
    meta_keywords,
    created_at,
    updated_at,
  };
};

const to2Decimal = (input: number | string) => {
  if (input.toString().includes("-")) {
    return Number(input);
  } else {
    return Number(Number(input).toFixed(2));
  }
};

export const calculatePrice = (
  netWeight: number,
  basePrice: number,
  weight: number
) => {
  return to2Decimal((basePrice * weight) / netWeight);
};

export const formatMenuData = ({ data }: { data: IDBMenu }): any => {
  if (!data || !data.menu_items || data.menu_items.length === 0) return [];

  return data.menu_items.map((menu) => ({
    id: menu.id,
    menu_item_title: menu.menu_item_title,
    slug: menu.url,
    is_mega_menu: menu.is_mega_menu,
    mega_menu: menu.is_mega_menu
      ? formatMenuData({ data: menu.mega_menu })
      : null,
    priority: menu.priority,
    children: menu.children.map((child) => ({
      id: child.id,
      menu_item_title: child.menu_item_title,
      slug: child.url,
      priority: child.priority,
    })),
  }));
};

export const transformDataToBannerConfig = (input: any) => {
  const extractValue = (name: string) =>
    input.content.find((item: any) => item.name === name)?.value?.[0]?.value;

  const bannerContent =
    input.content.find((item: any) => item.name === "banner_content")
      ?.inner_form || [];

  const content: IBannerContent[] = bannerContent.map((item: any) => {
    const media = item.find((subItem: any) => subItem.name === "media")?.value
      .preview;

    return {
      media: media,
      alt: item.find((subItem: any) => subItem.name === "alt")?.value,
      title: item.find((subItem: any) => subItem.name === "title")?.value,
      description: item.find((subItem: any) => subItem.name === "description")
        ?.value,
      className: "",
      small: false,
      action: {
        link: item.find((subItem: any) => subItem.name === "btnlink")?.value,
        text: item.find((subItem: any) => subItem.name === "btnText")?.value,
      },
    };
  });

  return {
    content,
    type: extractValue("banner_type") as string,
    display_width: extractValue("display_width") as string,
    flow: extractValue("flow") as string,
    alignment: extractValue("alignment") as string,
  };
};

export const transformDataToListingConfig = (input: any) => {
  const extractValue = (name: string) =>
    input.content.find((item: any) => item.name === name)?.value?.[0]?.value;

  const extractContentValue = (name: string) =>
    input.content.find((item: any) => item.name === name)?.value || [];

  const type = extractValue("type");
  const contentType = extractValue("content_type");
  const title = extractContentValue("title");
  const largeCard = extractValue("largeCard") === 1;
  const extraFilters = extractValue("extraFilters") === 1;

  const filters = extractContentValue("filters").map((item: any) => item.label);

  const formatContentItem = (item: IDBProduct | any) => {
    if (contentType === "product") {
      return formatProduct(item);
    }

    const baseConfig = {
      title: item.display_name,
      img: item.icon,
      desc: item.short_summary,
      alt: item.icon_alt,
      hasDesc: true,
    };

    if (contentType === "category") {
      return { ...baseConfig, link: item.meta_slug };
    }

    if (contentType === "sub-category") {
      return {
        ...baseConfig,
        slug: item.meta_slug,
        link: constructSlug([
          item.product_category.meta_slug || "",
          item.meta_slug,
        ]),
      };
    }
  };

  let content = extractContentValue("content");

  let taste: any[] = [];

  let totalPages = 0;

  let count = 0;

  if (Array.isArray(content)) {
    content = content.map(formatContentItem);
  } else {
    totalPages = Math.ceil(
      content.product_list.count / content.product_list.page_size
    );
    count = content.product_list.count;
    taste = constructTasteOptions(content.product_taste_list);
    content = content.product_list.results.map(formatProduct);
  }

  const fetchUrl = `/shop/product/search/${
    input.type === "category"
      ? `?category=${input.page_id}`
      : input.type === "sub_category"
      ? `?subcategory=${input.page_id}`
      : ""
  }`;

  return {
    content,
    type,
    title,
    largeCard,
    extraFilters,
    filters,
    fetchUrl,
    totalPages,
    count,
    taste,
  };
};

export const transformDataToMultipleListingConfig = (input: any) => {
  const listContent =
    input.content.find((item: any) => item.name === "list_content")
      ?.inner_form || [];

  const content = listContent.map((item: any) => {
    const title = item.find((subItem: any) => subItem.name === "title")?.value;

    const contentItems =
      item.find((subItem: any) => subItem.name === "content")?.value || [];

    return {
      title,
      content: contentItems.map((product: IDBProduct) =>
        formatProduct(product)
      ),
    };
  });

  return {
    content,
  };
};

export const transformDataToProductDealsConfig = (input: any) => {
  const extractValue = (name: string) =>
    input.content.find((item: any) => item.name === name)?.value;

  const dealContent =
    input.content.find((item: any) => item.name === "deal_content")
      ?.inner_form || [];

  const content = dealContent.map((item: any) => ({
    media: item.find((subItem: any) => subItem.name === "media")?.value.preview,
    title: item.find((subItem: any) => subItem.name === "title")?.value,
    description: item.find((subItem: any) => subItem.name === "description")
      ?.value,
    action: {
      link: item.find((subItem: any) => subItem.name === "btnlink")?.value,
      text: item.find((subItem: any) => subItem.name === "btnText")?.value,
    },
  }));

  return {
    content,
    main_title: extractValue("main_title"),
    main_description: extractValue("main_description"),
  };
};

export const getPaymentModeId = (payment_mode: string) => {
  switch (payment_mode) {
    case "netbanking":
      return 16;
    case "upi":
      return 15;
    case "card":
      return 14;
    case "wallet":
      return 19;
    default:
      return 0;
  }
};

export function isTokenValid(token: any): boolean {
  const currentTime = Math.floor(Date.now() / 1000);

  if (token.exp < currentTime) {
    return false;
  }

  if (token.iat > currentTime) {
    return false;
  }

  return true;
}

export function generateOrganizationStucturedData(site_config: SiteConfig) {
  return {
    "@context": "https://schema.org",
    "@type": ["Organization", "OnlineBusiness"],
    "@id": `${site_config.site_url}#organization`,
    name: site_config.site_name,
    legalName: site_config.site_name,
    alternateName: "Avarya",
    email: site_config.site_contact_email,
    url: site_config.site_url,
    acceptedPaymentMethod:
      "Cash, UPI, Debit Card, Credit Card, Cheque, Net Banking",
    brand: {
      "@type": "Brand",
      name: site_config.site_name,
    },
    logo: {
      "@context": "https://schema.org",
      "@type": "ImageObject",
      url: site_config.site_header_logo_link,
      caption: "Logo",
      width: 120,
      height: 60,
    },
    sameAs: [site_config.facebook, site_config.instagram, site_config.youtube],
  };
}

export function generateWebsiteStructuredData(site_config: SiteConfig) {
  return {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: site_config.site_name,
    url: site_config.site_url,
    inLanguage: "en-IN",
    potentialAction: {
      "@type": "SearchAction",
      target: `${site_config.site_url}/search?query={search_term_string}`,
      "query-input": "required name=search_term_string",
    },
  };
}

export function generateOnlineStoreStructuredData(site_config: SiteConfig) {
  return {
    "@context": "https://schema.org",
    "@type": "OnlineStore",
    logo: {
      "@context": "https://schema.org",
      "@type": "ImageObject",
      url: site_config.site_header_logo_link,
      caption: "Logo",
      width: 120,
      height: 60,
    },
    url: site_config.site_url,
    name: site_config.site_name,
    description:
      "Avarya Retail Pvt Ltd is a manufacturer and retailer of a range of delectable mithais, namkeens and dry fruits. A premium quality food store, Avarya was established in an endeavour to reviving the traditional flavours of India by bringing authentic sweets and snacks, along with exotic dry fruits to urban consumers. We also offer a range of gifting solutions to corporate organizations in customizable packages for meeting their requirements.",
  };
}

function formatLocation(input: string) {
  // Remove the word "AVARYA" and trim extra spaces
  let filtered = input.replace("AVARYA", "").trim();

  // Capitalize the first letter of each word and make the rest lowercase
  return filtered
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function generateLocalBusinessStructureData(
  site_config: SiteConfig,
  store?: IStores
) {
  const schema: any = {
    "@context": "https://schema.org",
    "@type": ["LocalBusiness", "ConvenienceStore"],
    name: store?.store_name ?? site_config.site_name,
    email: store?.email ?? site_config.site_contact_email,
    description:
      "Avarya Retail Pvt Ltd is a manufacturer and retailer of a range of delectable mithais, namkeens and dry fruits. A premium quality food store, Avarya was established in an endeavour to reviving the traditional flavours of India by bringing authentic sweets and snacks, along with exotic dry fruits to urban consumers. We also offer a range of gifting solutions to corporate organizations in customizable packages for meeting their requirements.",
    image: {
      "@type": "ImageObject",
      url: site_config.site_header_logo_link,
      caption: "Logo",
      width: 120,
      height: 60,
    },
    url: site_config.site_url,
    telephone: `+91${store?.contact_number ?? site_config.site_contact_number}`,
    priceRange: "₹₹₹",
    geo: {
      "@type": "GeoCoordinates",
      latitude: `${store?.latitude ?? 18.99445514212209}`,
      longitude: `${store?.longitude ?? 72.83641522788403}`,
    },
    address: {
      "@type": "PostalAddress",
      streetAddress:
        store?.address?.address_line_1 ?? "Shop No 5 to 9, Avighna 9",
      addressLocality: store
        ? store?.address_locality ?? formatLocation(store?.store_name)
        : "Lalbaug",
      postalCode: `${store?.address?.pincode ?? 400012}`,
      addressCountry: "IN",
    },
    hasMap: store?.store_gmap,
    parentOrganization: {
      id: `${site_config.site_url}#organization`,
    },
    paymentAccepted: "Cash, UPI, Debit Card, Credit Card, Cheque, Net Banking",
    openingHoursSpecification: {
      "@type": "OpeningHoursSpecification",
      dayOfWeek: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      opens: "09:00",
      closes: "21:00",
    },
    sameAs: [site_config.facebook, site_config.instagram, site_config.youtube],
  };

  return schema;
}

export function generateBreadcrumbStructuredData(
  site_config: SiteConfig,
  type: "Static" | "Product" | "Category" | "Sub Category",
  slug?: string[]
): any {
  const baseUrl = site_config.site_url;

  // Convert slug to breadcrumb parts
  const breadcrumbParts = slug?.map((part, index) => {
    const name = part
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return {
      "@type": "ListItem",
      position: index + 2, // Start from 2 since "Home" is at position 1
      name: name,
      item: `${baseUrl}/${slug.slice(0, index + 1).join("/")}`,
    };
  });

  // Add Home breadcrumb at the beginning
  const breadcrumbData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    name: `${type} Breadcrumb`,
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: site_config.front_page.page_name,
        item: baseUrl,
      },
      ...(breadcrumbParts || []),
    ],
  };

  return breadcrumbData;
}

export function generateItemPageStructuredData(
  site_config: SiteConfig,
  productDetails: TProductDetails,
  reviews: IReview[],
  slug?: string[]
) {
  const itemPageSchema: any = {
    "@context": "https://schema.org",
    "@type": "ItemPage",
    "@id": `${site_config.site_url}/${productDetails?.slug}/#itempage`,
    url: `${site_config.site_url}/${productDetails?.slug}`,
    name: productDetails?.title,
    description: extractContent(productDetails.description),
    inLanguage: "en-IN",
    isPartOf: generateWebsiteStructuredData(site_config),
    datePublished: productDetails?.created_at,
    dateModified: productDetails?.created_at,
    primaryImageOfPage: {
      "@context": "https://schema.org",
      "@type": "ImageObject",
      "@id": `${site_config.site_url}/${productDetails?.slug}/#mainImage`,
      url: productDetails.images?.[0]?.img,
      caption: productDetails.images?.[0]?.alt,
      width: 1024,
      height: 1024,
    },
    image: productDetails.images.map((item) => ({
      "@context": "https://schema.org",
      "@type": "ImageObject",
      url: item.img,
      caption: item.alt,
      width: 1024,
      height: 1024,
    })),
    thumbnail: productDetails.thumbnails.map((item) => ({
      "@context": "https://schema.org",
      "@type": "ImageObject",
      url: item.img,
      caption: item.alt,
      width: 200,
      height: 200,
    })),
    breadcrumb: generateBreadcrumbStructuredData(site_config, "Product", slug),
  };

  if (reviews?.length > 0) {
    const reviewSchema = reviews?.map((review) => ({
      "@type": "Review",
      reviewBody: review.review_comment,
      reviewRating: {
        "@type": "Rating",
        bestRating: "5",
        ratingValue: review.rating.toString(),
        worstRating: "1",
      },
      datePublished: review.review_date.split("T")[0],
      author: {
        "@type": "Person",
        name: review.customer_details.name,
      },
    }));

    (itemPageSchema["lastReviewed"] = reviews?.[0]?.created_at),
      (itemPageSchema["reviewedBy"] = reviews?.[0]?.customer_details?.name);
    itemPageSchema["review"] = reviewSchema;
  }

  return itemPageSchema;
}

export function extractContent(html: string): string {
  // Replace <br> tags with line breaks
  const content = html.replace(/<br\s*\/?>/gi, "\n");

  // Remove any remaining HTML tags
  const textContent = content.replace(/<\/?[^>]+(>|$)/g, "");

  return textContent.trim();
}

function findBestAndWorstRatings(reviews: IReview[]) {
  if (reviews.length === 0) {
    return {
      bestRating: null,
      worstRating: null,
    };
  }

  let bestRating = reviews[0].rating;
  let worstRating = reviews[0].rating;

  for (const review of reviews) {
    if (review.rating > bestRating) {
      bestRating = review.rating;
    }
    if (review.rating < worstRating) {
      worstRating = review.rating;
    }
  }

  return {
    bestRating,
    worstRating,
  };
}

export function generateFAQStructuredData(faqs: IFAQ[]) {
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    name: `FAQs`,
    mainEntity: faqs.map((faq) => ({
      "@type": "Question",
      name: faq.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: extractContent(faq.answer),
      },
    })),
  };
}

export function generateItemListStructuredData(
  site_config: SiteConfig,
  data: TProductDetails[]
) {
  return {
    "@context": "https://schema.org",
    "@type": "ItemList",
    itemListElement: data.map((item, index: number) => ({
      "@type": "ListItem",
      position: index + 1,
      url: `${site_config.site_url}/${item.slug}`,
      name: item.title,
      description: extractContent(item.description),
      image: {
        "@context": "https://schema.org",
        "@type": "ImageObject",
        url: item.thumbnails?.[0]?.img,
        caption: item.thumbnails?.[0]?.alt,
        width: 200,
        height: 200,
      },
    })),
    numberOfItems: data.length,
  };
}

export function generateSimilarToStructuredData(
  site_config: SiteConfig,
  productDetails: TProductDetails[]
) {
  return productDetails?.map((productDetail) => {
    const productItemSchema: any = {
      "@context": "https://schema.org",
      "@type": "Product",
      name: productDetail.title,
      image: {
        "@context": "https://schema.org",
        "@type": "ImageObject",
        url: productDetail?.thumbnails?.[0]?.img,
        caption: productDetail?.thumbnails?.[0]?.alt,
        width: 200,
        height: 200,
      },
      additionalProperty: {
        name: "Weight",
        value: productDetail?.weights?.map(
          (item) => `${item.value}${item.uom}`
        ),
      },
      weight: productDetail?.weights?.map((item) => ({
        "@type": "QuantitativeValue",
        value: item.value,
        unitText: item.uom === "g" ? "gms" : item.uom,
      })),
      offers: {
        url: `${site_config.site_url}/${productDetail?.slug}`,
        priceCurrency: "INR",
        priceSpecification: {
          "@type": "PriceSpecification",
          valueAddedTaxIncluded: true,
        },
        priceValidUntil: "2030-12-31",
        // hasMerchantReturnPolicy:"",
        // shippingDetails:"",
        // seller: generateOrganizationStucturedData(site_config),
      },
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: productDetail.rating.toString(),
        bestRating: "5",
        worstRating: "1",
        ratingCount: "580",
        reviewCount: "580",
        // ratingCount: reviews.length.toString(),
        // reviewCount: reviews.length.toString(),
      },
    };

    const sortedWeights = productDetail.weights?.sort(
      (a, b) => a.value - b.value
    );

    if (sortedWeights?.length === 1) {
      productItemSchema["offers"] = {
        ...productItemSchema["offers"],
        "@type": "Offer",
        price: productDetail.price.toString(),
      };
    } else {
      productItemSchema["offers"] = {
        ...productItemSchema["offers"],
        "@type": "AggregateOffer",
        offerCount: sortedWeights?.length || 1,

        lowPrice: calculatePrice(
          Number(productDetail.net_weight),
          productDetail.price,
          sortedWeights?.[0]?.value
        ).toString(),

        highPrice: calculatePrice(
          Number(productDetail.net_weight),
          productDetail.price,
          sortedWeights?.[sortedWeights.length - 1]?.value
        ).toString(),
      };
    }

    return productItemSchema;
  });
}

export function generateProductStructuredData(
  site_config: SiteConfig,
  productDetails: TProductDetails,
  reviews: IReview[],
  similar_products?: TProductDetails[]
) {
  const fullImages = productDetails.images.map((item) => ({
    "@context": "https://schema.org",
    "@type": "ImageObject",
    url: item.img,
    caption: item.alt,
    width: 1024,
    height: 1024,
  }));

  const thumbnailImages = productDetails.thumbnails.map((item) => ({
    "@context": "https://schema.org",
    "@type": "ImageObject",
    url: item.img,
    caption: item.alt,
    width: 200,
    height: 200,
  }));

  const productSchema: any = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: productDetails.title,
    image: [...fullImages, ...thumbnailImages],
    description: extractContent(productDetails.description),
    brand: {
      "@type": "Brand",
      name: site_config.site_name,
    },
    additionalProperty: {
      name: "Weight",
      value: productDetails?.weights?.map((item) => `${item.value}${item.uom}`),
    },
    weight: productDetails?.weights?.map((item) => ({
      "@type": "QuantitativeValue",
      value: item.value,
      unitText: item.uom === "g" ? "gms" : item.uom,
    })),
    countryOfOrigin: "India",
    keywords: productDetails.meta_keywords,
    category: `${productDetails.category}/${productDetails.sub_category}`,
    sku: productDetails.product.product_code.toString(),
    mpn: productDetails.product.product_code.toString(),
    offers: {
      url: `${site_config.site_url}/${productDetails?.slug}`,
      priceCurrency: "INR",
      availability: "https://schema.org/InStock",
      itemCondition: "https://schema.org/NewCondition",
      priceSpecification: {
        "@type": "PriceSpecification",
        valueAddedTaxIncluded: true,
      },
      priceValidUntil: "2030-12-31",
      seller: generateOrganizationStucturedData(site_config),
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: productDetails.rating.toString(),
      bestRating: "5",
      worstRating: "1",
      ratingCount: "580",
      reviewCount: "580",
      // ratingCount: reviews.length.toString(),
      // reviewCount: reviews.length.toString(),
    },
  };

  const sortedWeights = productDetails.weights?.sort(
    (a, b) => a.value - b.value
  );

  if (sortedWeights?.length === 1) {
    productSchema["offers"] = {
      ...productSchema["offers"],
      "@type": "Offer",
      price: productDetails.price.toString(),
    };
  } else {
    productSchema["offers"] = {
      ...productSchema["offers"],
      "@type": "AggregateOffer",
      offerCount: sortedWeights?.length || 1,

      // price: calculatePrice(
      //   1000,
      //   productDetails.price,
      //   sortedWeights?.[0]?.value
      // ).toString(),

      lowPrice: calculatePrice(
        Number(productDetails.net_weight),
        productDetails.price,
        sortedWeights?.[0]?.value
      ).toString(),

      highPrice: calculatePrice(
        Number(productDetails.net_weight),
        productDetails.price,
        sortedWeights?.[sortedWeights.length - 1]?.value
      ).toString(),
    };
  }

  // if (similar_products && similar_products?.length > 0) {
  //   productSchema["isSimilarTo"] = generateSimilarToStructuredData(
  //     site_config,
  //     similar_products
  //   );
  // }

  if (reviews?.length > 0) {
    const reviewSchema = reviews?.map((review) => ({
      "@type": "Review",
      reviewBody: review.review_comment,
      reviewRating: {
        "@type": "Rating",
        bestRating: "5",
        ratingValue: review.rating.toString(),
        worstRating: "1",
      },
      datePublished: review.review_date.split("T")[0],
      author: {
        "@type": "Person",
        name: review.customer_details.name,
      },
    }));

    productSchema["review"] = reviewSchema;
  }

  return productSchema;
}
export function constructStructuredData({
  site_config,
  stores_data,
  slug,
  type,
  productDetails,
  reviews,
  products,
  faqs = [],
}: TStructuredData) {
  if (
    products &&
    products?.length > 0 &&
    (type === "Category" || type === "Sub Category")
  ) {
    return [generateItemListStructuredData(site_config, products)];
  }

  const structuredData: any = [
    generateOrganizationStucturedData(site_config),
    generateOnlineStoreStructuredData(site_config),
    generateWebsiteStructuredData(site_config),
  ];

  if (stores_data && stores_data?.length > 0) {
    structuredData.push(
      ...stores_data.flatMap((store) =>
        generateLocalBusinessStructureData(site_config, store)
      )
    );
  }

  if (slug?.length > 0) {
    structuredData.push(
      generateBreadcrumbStructuredData(site_config, type, slug)
    );
  }

  if (faqs && faqs.length > 0) {
    structuredData.push(generateFAQStructuredData(faqs));
  }

  if (productDetails && reviews) {
    structuredData.push(
      generateProductStructuredData(
        site_config,
        productDetails,
        reviews,
        products
      ),
      generateItemPageStructuredData(site_config, productDetails, reviews, slug)
    );
  }

  return structuredData;
}

export function constructTasteOptions(tastes: any[]) {
  return [
    { value: "", label: "Select..." },
    ...(tastes?.length > 0
      ? tastes?.map((item: any) => ({
          value: item.id,
          label: item.taste_name,
        }))
      : []),
  ];
}

export function applyProductSpecificDiscount(
  cartItem: ICartItem,
  appliedDiscount: IDiscountData
): ICartItem {
  const { id: product_id, weight_id } = cartItem;

  const new_data = appliedDiscount?.productDiscount?.find(
    (discount: any) =>
      discount.id === product_id && discount.weight_id === weight_id
  );

  if (new_data) {
    return {
      ...cartItem,
      payable_amount: new_data?.new_payable_amount || 0,
      discount_value: new_data?.discount_available || 0,
      discount_code: appliedDiscount.discount_code,
      discount_type: appliedDiscount.discount_type,
    };
  }

  return cartItem;
}

export const generateRazorpayOptions = (
  razorpayResponse: RazorpayOptionsResponse,
  paymentFailure: boolean,
  router: any,
  dispatch: AppDispatch,
  orderDetails: boolean = false,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return {
    key: STATIC_CONSTANTS.ENV_CONSTANTS.RAZORPAY_ID,
    amount: razorpayResponse.amount,
    currency: STATIC_CONSTANTS.RAZORPAY_CONSTANTS.DEFAULT_CURRENCY,
    name: razorpayResponse.notes.customer_name,
    contact: razorpayResponse.notes.customer_contact_number,
    email: razorpayResponse.notes.customer_email,
    description: "description",
    order_id: razorpayResponse.id,
    handler: async function (response: any) {
      try {
        const data: RazorpayOptionsPaymentDetails = {
          // paymentMappingId: razorpayResponse.payment_mapping_id || 0,
          orderCreationId: razorpayResponse.id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        const result = await fetch("/api/razorpay/verify/", {
          method: REQUEST_METHOD.POST,
          body: JSON.stringify(data),
          headers: { "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON },
        });

        const res = await result.json();

        if (res.isOk) {
          // setLoading(false);
          if (orderDetails) {
            router.refresh();
          } else {
            dispatch(setPlacedOrderId(razorpayResponse?.notes?.order_id));
            router.push("/success");
          }
        }
      } catch (error: any) {
        Sentry.captureException(new Error(JSON.stringify(error)));
        if (orderDetails) {
          router.refresh();
        } else {
          router.push("/account/orders");
        }
      }
    },
    prefill: {
      name: razorpayResponse.notes.customer_name,
      email: razorpayResponse.notes.customer_email,
      contact: razorpayResponse.notes.customer_contact_number,
    },
    modal: {
      ondismiss: async function () {
        if (orderDetails) {
          router.refresh();
        } else {
          router.push("/account/orders");
        }
      },
    },
  };
};
