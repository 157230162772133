"use client";

import React, { useRef, useState } from "react";
import Image from "next/image";
import Link from "next/link";

import { STATIC_CONSTANTS, useClickOutside } from "@/utils";
import { Button } from "./core-components";
import {
  setLoadingState,
  toggleMobileMenuState,
  useAppDispatch,
  useAppSelector,
} from "@/store";

import close from "@media/icons/icon-close.png";
import facebook from "@media/icons/icon-facebook.svg";
import instagram from "@media/icons/icon-instagram.svg";
import youtube from "@media/icons/icon-youtube.svg";
import { usePathname } from "next/navigation";

const MenuItemComponent = ({
  initialOpen,
  item,
  activeKeys,
  toggleClick,
  handleToggle,
  handleSubToggle,
  subActiveKeys,
}: {
  initialOpen: number;
  item: any;
  activeKeys: {
    [key: number]: boolean;
  };
  toggleClick: ({ slug }: { slug: string }) => void;
  handleToggle: (key: number) => void;
  handleSubToggle: (parentKey: number, key: number) => void;
  subActiveKeys: {
    [key: number]: {
      [key: number]: boolean;
    };
  };
}) => {
  return (
    <li
      className={
        activeKeys[item.id]
          ? "menu-item-has-children active"
          : "menu-item-has-children"
      }
    >
      {item.children.length > 0 || item.is_mega_menu ? (
        <React.Fragment>
          <span className="menu-expand" onClick={() => handleToggle(item.id)}>
            <i className="fi-rs-angle-small-down"></i>
          </span>
          <span className="link-span">{item.menu_item_title}</span>
          <ul
            className={
              initialOpen === item.id || activeKeys[item.id]
                ? "dropdown"
                : "d-none"
            }
          >
            {(item.is_mega_menu ? item.mega_menu : item.children).map(
              (subItem: any) => {
                return (
                  <SubMenuItemComponent
                    key={subItem.id || subItem.menu_item_title}
                    parentKey={item.id}
                    toggleClick={toggleClick}
                    subItem={subItem}
                    subActiveKeys={subActiveKeys}
                    handleSubToggle={handleSubToggle}
                  />
                );
              }
            )}
          </ul>
        </React.Fragment>
      ) : (
        <Link
          href={item.slug || ""}
          onClick={() => {
            toggleClick({ slug: item.slug });
          }}
        >
          {item.menu_item_title}
        </Link>
      )}
    </li>
  );
};

const SubMenuItemComponent = ({
  parentKey,
  toggleClick,
  subItem,
  subActiveKeys,
  handleSubToggle,
}: {
  parentKey: number;
  toggleClick: ({ slug }: { slug: string }) => void;
  subItem: any;
  handleSubToggle: (parentKey: number, key: number) => void;
  subActiveKeys: {
    [key: number]: {
      [key: number]: boolean;
    };
  };
}) => {
  return (
    <li>
      {subItem.children.length > 0 ? (
        <React.Fragment>
          <span
            className="menu-expand"
            onClick={() => handleSubToggle(parentKey, subItem.id)}
          >
            <i className="fi-rs-angle-small-down"></i>
          </span>
          <Link
            href={`/${subItem.slug}`}
            onClick={() => toggleClick({ slug: `/${subItem.slug}` })}
          >
            {subItem.menu_item_title}
          </Link>
          <ul
            className={
              subActiveKeys[parentKey]?.[subItem.id] ? "dropdown" : "d-none"
            }
          >
            {subItem.children.map((nestedSubItem: any) => (
              <li key={nestedSubItem.menu_item_title}>
                <Link
                  href={`/${nestedSubItem.slug}`}
                  onClick={() =>
                    toggleClick({ slug: `/${nestedSubItem.slug}` })
                  }
                >
                  {nestedSubItem.menu_item_title}
                </Link>
              </li>
            ))}
          </ul>
        </React.Fragment>
      ) : (
        <Link
          href={`/${subItem.slug}`}
          onClick={() => toggleClick({ slug: `/${subItem.slug}` })}
        >
          {subItem.label}
        </Link>
      )}
    </li>
  );
};

const MobileMenu = ({ data }: { data?: any }) => {
  const dispatch = useAppDispatch();
  const { toggleMobileMenu, siteConfig } = useAppSelector(
    (state) => state.common
  );

  const paths = usePathname();

  const [activeKeys, setActiveKeys] = useState<{ [key: number]: boolean }>({
    [data[0]?.id]: data[0]?.is_mega_menu === 1,
  });
  const [subActiveKeys, setSubActiveKeys] = useState<{
    [key: number]: { [key: number]: boolean };
  }>({});
  const menuRef = useRef<HTMLUListElement | null>(null);

  useClickOutside({
    ref: menuRef,
    callback: () => {
      setActiveKeys({});
      setSubActiveKeys({});
    },
  });

  const toggleClick = ({ slug }: { slug: string }) => {
    if (slug && slug !== paths && slug !== "#") {
      dispatch(setLoadingState(true));
    }
    dispatch(toggleMobileMenuState());
    document
      .querySelector("body")
      ?.classList.toggle("mobile-menu-active", toggleMobileMenu);
  };

  if (!toggleMobileMenu) {
    return null;
  }

  const handleToggle = (key: number) => {
    setActiveKeys((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const handleSubToggle = (parentKey: number, key: number) => {
    setSubActiveKeys((prev) => ({
      ...prev,
      [parentKey]: { ...prev[parentKey], [key]: !prev[parentKey]?.[key] },
    }));
  };

  return (
    <div
      className={`mobile-header-active mobile-header-wrapper-style ${
        toggleMobileMenu ? "sidebar-visible" : ""
      }`}
    >
      <div className="mobile-header-wrapper-inner">
        <div className="mobile-header-top">
          <div className="mobile-header-logo">
            <Link href="/">
              <Image
                width={120}
                height={60}
                src={
                  siteConfig?.site_header_logo_link ||
                  `${STATIC_CONSTANTS.S3_BUCKET_LINK}/${STATIC_CONSTANTS.HEADER_LOGO_LINK}`
                }
                alt="logo"
                className="h-100 w-100"
              />
            </Link>
          </div>
          <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
            <Button
              type="button"
              className="close-style search-close"
              onClick={() => toggleClick({ slug: "" })}
              text={<Image width={10} height={10} src={close} alt="Close" />}
            />
          </div>
        </div>
        <div className="mobile-header-content-area">
          <div className="mobile-menu-wrap mobile-header-border">
            <nav>
              <ul className="mobile-menu" ref={menuRef}>
                {data.map((item: any) => (
                  <MenuItemComponent
                    initialOpen={data?.[0]?.id}
                    key={item.id}
                    item={item}
                    activeKeys={activeKeys}
                    toggleClick={toggleClick}
                    handleToggle={handleToggle}
                    handleSubToggle={handleSubToggle}
                    subActiveKeys={subActiveKeys}
                  />
                ))}
              </ul>
            </nav>
          </div>
          <div className="mobile-social-icon mb-15 text-black d-flex align-items-center">
            <h6 className="text-grey-4">Follow Us</h6>
            <Link
              href={siteConfig?.facebook ?? ""}
              className="d-flex align-items-center"
              target="_blank"
            >
              <Image
                width={24}
                height={24}
                className="img-fluid h-100 w-100"
                src={facebook}
                alt="facebook-icon"
              />
            </Link>
            <Link
              href={siteConfig?.instagram ?? ""}
              className="d-flex align-items-center"
              target="_blank"
            >
              <Image
                width={24}
                height={24}
                className="img-fluid h-100 w-100"
                src={instagram}
                alt="instagram-icon"
              />
            </Link>
            <Link
              href={siteConfig?.youtube ?? ""}
              className="d-flex align-items-center"
              target="_blank"
            >
              <Image
                width={24}
                height={24}
                className="img-fluid h-100 w-100"
                src={youtube}
                alt="pintrest-icon"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export { MobileMenu };
