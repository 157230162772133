import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  apiRequestClient,
  calculatePrice,
  customRevalidateTag,
  formatProduct,
  ICartItem,
  InitializeCartLocally,
  IProductDiscount,
  REQUEST_CONTENT_TYPE,
  REQUEST_METHOD,
  SetCartLocally,
  STATUSES,
  to2Decimal,
} from "@/utils";
import { setCommonState } from "./commonSlice";
import { resetShippingDetails } from "./orderSlice";
import { FormikHelpers } from "formik";
import * as Sentry from "@sentry/nextjs";

const initialState = {
  cartItemsCounter: 0,
  cartItems: [] as ICartItem[],
  currentCart: [] as ICartItem[],
  cart_status: STATUSES.IDLE as string,
  appliedDiscount: {
    discount_code: "",
    discount_value: 0,
    total_discount: 0,
    discount_type: "flat" as "flat" | "percentage",
    productDiscount: [] as IProductDiscount[],
  },
  subTotal: 0,
  shipping: 0,
  tax: 0,
  total: 0,
};

export const applyDiscount = createAsyncThunk(
  "cart/applyDiscount",
  async (
    { code, actions }: { code: string; actions?: FormikHelpers<any> },
    { dispatch, getState, rejectWithValue }
  ) => {
    dispatch(
      setCommonState({ status: STATUSES.LOADING, type: "apply-discount" })
    );
    try {
      const response = await apiRequestClient({
        url: `/api/discount/?code=${code}`,
        method: REQUEST_METHOD.GET,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
      });

      dispatch(
        setCommonState({ status: STATUSES.IDLE, type: "apply-discount" })
      );

      actions?.resetForm();

      return response.result;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
      dispatch(
        setCommonState({ status: STATUSES.ERROR, type: "apply-discount" })
      );
    }
  }
);

export const removeDiscount = createAsyncThunk(
  "cart/removeDiscount",
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(
      setCommonState({ status: STATUSES.LOADING, type: "remove-discount" })
    );
    try {
      const response = await apiRequestClient({
        url: `/api/discount`,
        method: REQUEST_METHOD.DELETE,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
      });

      dispatch(
        setCommonState({ status: STATUSES.IDLE, type: "remove-discount" })
      );

      return response.result;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
      dispatch(
        setCommonState({ status: STATUSES.ERROR, type: "remove-discount" })
      );
    }
  }
);

export const addProduct = createAsyncThunk(
  "cart/addProduct",
  async (
    {
      product,
      db = false,
      onSuccess,
      local,
    }: { product: any; db?: boolean; onSuccess?: () => void; local?: boolean },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      cart: { cartItems },
    } = getState() as RootState;

    const cartToUse = JSON.parse(JSON.stringify(cartItems));

    const existingProductIndex = cartToUse.findIndex(
      (item: any) =>
        item.id === product.id && item.weight_id === product.weight_id
    );

    onSuccess && onSuccess();

    if (existingProductIndex !== -1) {
      if (db) {
        cartToUse[existingProductIndex].quantity =
          Number(product?.quantity) || 1;
      } else {
        const new_quantity =
          cartToUse[existingProductIndex].quantity +
          (Number(product?.quantity) > 1 ? Number(product?.quantity) : 1);

        cartToUse[existingProductIndex].quantity = new_quantity;

        cartToUse[existingProductIndex].payable_amount = to2Decimal(
          product.price * new_quantity
        );
      }

      return cartToUse;
    }

    const cartToSet = [
      {
        ...product,
        quantity: Number(product?.quantity || 1),
      },
      ...cartToUse,
    ];

    return cartToSet;
  }
);

export const updateQuantity = createAsyncThunk(
  "cart/updateQuantity",
  async (
    {
      index,
      quantity,
      local,
    }: { index: number; quantity: number; local?: boolean },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      cart: { cartItems },
    } = getState() as RootState;

    const cartToUse = JSON.parse(JSON.stringify(cartItems));

    if (quantity === 0) {
      return cartToUse.filter((_: any, i: number) => i !== index);
    }

    if (index !== -1) {
      cartToUse[index].quantity = quantity;
      cartToUse[index].payable_amount = to2Decimal(
        cartToUse[index].price * quantity
      );

      dispatch(
        setCartItemsCounter(
          cartToUse?.reduce((acc: number, item: any) => acc + item.quantity, 0)
        )
      );

      return cartToUse;
    }

    return cartToUse;
  }
);

export const removeProduct = createAsyncThunk(
  "cart/removeProduct",
  async (
    { index, local }: { index: number; local?: boolean },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      cart: { cartItems },
    } = getState() as RootState;

    const cartToUse = JSON.parse(JSON.stringify(cartItems));

    const newCart = cartToUse.filter((_: any, i: number) => i !== index);

    dispatch(
      setCartItemsCounter(
        newCart?.reduce((acc: number, item: any) => acc + item.quantity, 0)
      )
    );

    return newCart;
  }
);

export const addToCart = createAsyncThunk(
  "cart/addToCart",
  async (
    {
      product_id,
      quantity,
      weight_id,
      onSuccess,
      index,
    }: {
      product_id: number;
      quantity: number;
      weight_id: number;
      onSuccess: () => void;
      index?: number;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    dispatch(
      setCommonState({
        status: STATUSES.LOADING,
        type: `add-cartItem-${product_id}${index ? `-${index}` : ""}`,
      })
    );
    try {
      const {
        cart: { appliedDiscount },
      } = getState() as RootState;

      const response = await fetch("/api/cart", {
        method: REQUEST_METHOD.POST,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          product_id: product_id,
          quantity: quantity,
          weight_id: weight_id,
        }),
      }).then((res) => res?.json());

      const formattedProduct = formatProduct(response.result.product_details);

      const selectedWeight =
        formattedProduct.weights.find(
          (weight: any) => weight.id === response.result.weight_id
        )?.value ?? 0;

      const productData = {
        ...formattedProduct,
        price: calculatePrice(
          Number(formattedProduct.net_weight),
          formattedProduct.price,
          selectedWeight
        ),
      };

      const dataToAdd = {
        ...response.result,
        cart_id: response.result.id,
        quantity: response.result.quantity,
        weight_id: response.result.weight_id,
        weight: productData.weights.find(
          (weight: any) => weight.id === response.result.weight_id
        )?.value,
        payable_amount: productData.price * response.result.quantity,
        discount_value: 0,
        discount_code: "",
        discount_type: "flat",
        ...productData,
      };

      delete dataToAdd["product_details"];

      // customRevalidateTag("mini-cart");

      dispatch(
        setCommonState({
          status: STATUSES.IDLE,
          type: `add-cartItem-${product_id}`,
        })
      );

      // onSuccess && onSuccess();

      appliedDiscount &&
        appliedDiscount?.discount_value > 0 &&
        dispatch(removeDiscount());

      dispatch(
        addProduct({ product: dataToAdd, db: true, onSuccess, local: false })
      );

      return response.result;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
      dispatch(
        setCommonState({
          status: STATUSES.ERROR,
          type: `add-cartItem-${product_id}`,
        })
      );
    }
  }
);

export const removeCartItem = createAsyncThunk(
  "cart/removeCartItem",
  async (
    { cart_id }: { cart_id: number },
    { dispatch, getState, rejectWithValue }
  ) => {
    dispatch(
      setCommonState({
        status: STATUSES.LOADING,
        type: `delete-cartItem-${cart_id}`,
      })
    );

    const {
      cart: { cartItems, appliedDiscount },
    } = getState() as RootState;

    const cartToUse = JSON.parse(JSON.stringify(cartItems));

    const existingProductIndex = cartToUse.findIndex(
      (item: any) => item.cart_id === cart_id
    );

    try {
      const response = await fetch(`/api/cart/${cart_id}/`, {
        method: REQUEST_METHOD.DELETE,
        headers: {
          "Content-Type": "application/json",
        },
      });

      appliedDiscount &&
        appliedDiscount?.discount_value > 0 &&
        dispatch(removeDiscount());

      dispatch(
        setCommonState({
          status: STATUSES.IDLE,
          type: `delete-cartItem-${cart_id}`,
        })
      );

      customRevalidateTag("cart");

      dispatch(
        removeProduct({
          index: existingProductIndex,
          local: false,
        })
      );

      // customRevalidateTag("mini-cart");
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
      dispatch(
        setCommonState({
          status: STATUSES.ERROR,
          type: `delete-cartItem-${cart_id}`,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const updateCartItem = createAsyncThunk(
  "cart/updateCartItem",
  async (
    { cart_id, quantity }: { cart_id: number; quantity: number },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      cart: { cartItems, appliedDiscount },
    } = getState() as RootState;

    const cartToUse = JSON.parse(JSON.stringify(cartItems));

    const existingProductIndex = cartToUse.findIndex(
      (item: any) => item.cart_id === cart_id
    );

    if (quantity > 0) {
      try {
        const response = await fetch(`/api/cart/${cart_id}`, {
          method: REQUEST_METHOD.PUT,
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ quantity: quantity }),
        }).then((res) => res?.json());

        appliedDiscount &&
          appliedDiscount?.discount_value > 0 &&
          dispatch(removeDiscount());

        dispatch(
          updateQuantity({
            index: existingProductIndex,
            quantity: quantity,
            local: false,
          })
        );

        // customRevalidateTag("mini-cart");

        return response;
      } catch (error: any) {
        Sentry.captureException(new Error(JSON.stringify(error)));
        rejectWithValue(error.message);
      }
    } else {
      dispatch(removeCartItem({ cart_id: cart_id }));
    }
  }
);

export const setWholeCart = createAsyncThunk(
  "cart/setWholeCart",
  async (
    {
      onSuccess,
      local,
    }: { onSuccess?: (value: ICartItem[]) => void; local?: boolean },
    { dispatch, rejectWithValue }
  ) => {
    dispatch(resetCartState());

    let cartToSet: ICartItem[] = [];

    if (local) {
      cartToSet = InitializeCartLocally();
    } else {
      dispatch(
        setCommonState({ status: STATUSES.LOADING, type: "fetch-cart" })
      );

      try {
        const cartItems = await fetch(`/api/cart/`, {
          method: REQUEST_METHOD.GET,
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => res?.json());

        for (let i in cartItems.result.results) {
          try {
            const formattedProduct = formatProduct(
              cartItems.result.results[i].product_details
            );

            const selectedWeight =
              formattedProduct.weights.find(
                (weight: any) =>
                  weight.id === cartItems.result.results[i].weight_id
              )?.value ?? 0;

            const productData = {
              ...formattedProduct,
              price: calculatePrice(
                Number(formattedProduct.net_weight),
                formattedProduct.price,
                selectedWeight
              ),
            };

            const dataToAdd = {
              ...cartItems.result.results[i],
              cart_id: cartItems.result.results[i].id,
              quantity: cartItems.result.results[i].quantity,
              weight_id: cartItems.result.results[i].weight_id,
              weight: productData.weights.find(
                (weight: any) =>
                  weight.id === cartItems.result.results[i].weight_id
              )?.value,
              payable_amount:
                productData.price * cartItems.result.results[i].quantity,
              discount_value: 0,
              discount_code: "",
              discount_type: "flat",
              ...productData,
            };

            delete dataToAdd["product_details"];

            cartToSet.push(dataToAdd);
          } catch (error: any) {
            Sentry.captureException(new Error(JSON.stringify(error)));
            rejectWithValue(error.message);
          }
        }

        dispatch(setCommonState({ status: STATUSES.IDLE, type: "fetch-cart" }));
      } catch (error: any) {
        Sentry.captureException(new Error(JSON.stringify(error)));
        rejectWithValue(error.message);
        dispatch(
          setCommonState({ status: STATUSES.ERROR, type: "fetch-cart" })
        );
      }
    }

    dispatch(
      setCartItemsCounter(
        cartToSet.reduce((acc, item) => acc + item.quantity, 0)
      )
    );
    local &&
      dispatch(setCommonState({ status: STATUSES.IDLE, type: "fetch-cart" }));
    onSuccess && onSuccess(cartToSet);
    return cartToSet;
  }
);

export const clearCart = createAsyncThunk(
  "cart/clearCart",
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(
      setCommonState({
        status: STATUSES.LOADING,
        type: `clear-cartItem`,
      })
    );
    try {
      const response = await fetch(`/api/cart/`, {
        method: REQUEST_METHOD.DELETE,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res?.json());

      dispatch(resetShippingDetails());

      dispatch(resetCartState());

      dispatch(
        setCommonState({
          status: STATUSES.IDLE,
          type: `clear-cartItem`,
        })
      );

      customRevalidateTag("cart");

      // customRevalidateTag("mini-cart");
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
      dispatch(
        setCommonState({
          status: STATUSES.IDLE,
          type: `clear-cartItem`,
        })
      );
    }
  }
);

const updatePrices = (cartItems: any[]) => {
  const subTotal = to2Decimal(
    cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0)
  );
  const tax = to2Decimal(subTotal * 0.18);
  const total = to2Decimal(subTotal + tax);
  return { subTotal, tax, total };
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartItemsCounter: (state, action) => {
      state.cartItemsCounter = action.payload;
    },
    incrementCartItemsCounter: (state) => {
      state.cartItemsCounter += 1;
    },
    decrementCartItemsCounter: (state) => {
      state.cartItemsCounter -= 1;
    },
    resetCartState: (state) => {
      state.cartItems = [];
      state.cartItemsCounter = 0;
      state.subTotal = 0;
      state.shipping = 0;
      state.tax = 0;
      state.total = 0;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(setWholeCart.fulfilled, (state, action) => {
        state.cartItems = action.payload;
      })
      .addCase(applyDiscount.fulfilled, (state, action) => {
        state.appliedDiscount = {
          discount_code: action.meta.arg.code,
          total_discount: action.payload?.discount_available,
          discount_value: action.payload?.discount_value,
          discount_type: action.payload?.type?.toLowerCase()?.split(" ")?.[0],
          productDiscount: action.payload?.discount_available_products,
        };
      })
      .addCase(removeDiscount.fulfilled, (state, action) => {
        state.appliedDiscount = {
          discount_code: "",
          discount_value: 0,
          discount_type: "flat",
          total_discount: 0,
          productDiscount: [],
        };
      })
      .addMatcher(
        isAnyOf(
          addProduct.fulfilled,
          removeProduct.fulfilled,
          updateQuantity.fulfilled
        ),
        (state, action) => {
          action.meta.arg.local && SetCartLocally(action.payload);
          state.cartItems = action.payload;
          const { subTotal, tax, total } = updatePrices(action.payload);
          state.subTotal = subTotal;
          state.tax = tax;
          state.total = total;
        }
      )
      .addMatcher(
        isAnyOf(
          addToCart.pending,
          updateCartItem.pending,
          removeCartItem.pending,
          clearCart.pending
        ),
        (state) => {
          state.cart_status = STATUSES.LOADING;
        }
      )
      .addMatcher(
        isAnyOf(
          addToCart.fulfilled,
          updateCartItem.fulfilled,
          removeCartItem.fulfilled,
          setWholeCart.fulfilled,
          clearCart.fulfilled
        ),
        (state) => {
          customRevalidateTag("cart");
          // customRevalidateTag("mini-cart");
          state.cart_status = STATUSES.IDLE;
          const { subTotal, tax, total } = updatePrices(state.cartItems);

          state.subTotal = subTotal;
          state.tax = tax;
          state.total = total;
        }
      );
  },
});

export const {
  setCartItemsCounter,
  incrementCartItemsCounter,
  decrementCartItemsCounter,

  resetCartState,
} = cartSlice.actions;

export default cartSlice.reducer;
