"use client";

import React from "react";
import {
  fetchCurrentPageData,
  resetCurrentPageData,
  setLoadingState,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import { useEffect, useMemo, useRef } from "react";
import { Widget } from "./Widget";
import { SiteConfig, STATUSES } from "@/utils";
import { Loader } from "./core-components";

const ProgressiveLoading = ({
  isFrontPage = false,
  pageData,
  slug,
  isLoggedIn,
  nonce,
  site_config,
}: {
  pageData: any;
  slug: string[];
  nonce?: string | null;
  isFrontPage?: boolean;
  isLoggedIn: boolean;
  site_config: SiteConfig;
}) => {
  const dispatch = useAppDispatch();
  const { currentPageData, state } = useAppSelector((state) => state.common);
  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  const currentSlug = slug?.join("/");

  const renderPage = useMemo(
    () => (
      <React.Fragment>
        <Widget
          site_config={site_config}
          nonce={nonce}
          slug={slug}
          pageData={currentPageData?.data || pageData}
          loadingRef={loadMoreRef}
        />
        {state.status === STATUSES.LOADING &&
          state.type === "fetch-widgets" && (
            <section className="section-padding-12 pb-15">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <p>Hang on, loading content</p>
                <span className="text-primary">
                  <Loader />
                </span>
              </div>
            </section>
          )}
      </React.Fragment>
    ),
    [
      currentPageData?.data?.content?.length,
      pageData?.content?.length,
      state.type,
      state.status,
    ]
  );

  useEffect(() => {
    dispatch(setLoadingState(false));
    dispatch(
      fetchCurrentPageData({
        data: pageData,
        slug: currentSlug,
        isFrontPage: isFrontPage,
        page: 1,
        isLoggedIn: isLoggedIn,
      })
    );
    return () => {
      dispatch(resetCurrentPageData());
      dispatch(setLoadingState(false));
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          currentPageData?.page < pageData.total_pages
        ) {
          // Load 4 more widgets when scrolled to the bottom
          dispatch(
            fetchCurrentPageData({
              slug: currentSlug,
              isFrontPage: isFrontPage,
              page: currentPageData?.page + 1,
              isLoggedIn,
            })
          );
        }
      },
      {
        rootMargin: "50px", // Trigger loading a bit before reaching the end
      }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [
    pageData?.content?.length,
    currentPageData?.page,
    currentPageData?.data?.content?.length,
  ]);

  return renderPage;
};

export default ProgressiveLoading;
