"use client";

import React from "react";
import { SiteConfig, useNetworkStatus } from "@/utils";
import { NoInternetConnection } from "./NoInternetConnection";
import { setSiteConfig, useAppDispatch, useAppSelector } from "@/store";
import { DynamicLoader } from "./group-components";
import { Breadcrumb } from "./core-components";

const ConnectionStatusWrapper = ({
  siteData,
  children,
}: {
  siteData: SiteConfig;
  children: React.ReactNode;
}) => {
  const dispatch = useAppDispatch();

  const { loading_state, scroll } = useAppSelector((state) => state.common);

  const isOnline = useNetworkStatus({
    init: () => dispatch(setSiteConfig(siteData)),
  });

  return isOnline ? (
    <>
      {loading_state && (
        <div className={scroll ? "full" : ""}>
          <DynamicLoader />
        </div>
      )}
      <Breadcrumb
        homeElement={"Home"}
        separator={<li className="d-inline">{">"}</li>}
        activeClasses="text-primary"
        listClasses="breadcrumb px-2"
        capitalizeLinks
      />
      {children}
    </>
  ) : (
    <NoInternetConnection />
  );
};

export { ConnectionStatusWrapper };
