"use client";

import { STATIC_CONSTANTS } from "@/utils";
import Image from "next/image";
import React, { useMemo, useState } from "react";
import SwiperCore, { Navigation, Pagination, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Navigation, Thumbs, Pagination]);

const ThumbSlider = ({ product }: { product: any }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | null>(null);

  const images = useMemo(
    () => [...product?.images, STATIC_CONSTANTS.FSSAI_VERIFIED_LINK.full],
    [product?.id, product?.images?.length]
  );

  const thumbnails = useMemo(
    () => [
      ...product?.thumbnails,
      STATIC_CONSTANTS.FSSAI_VERIFIED_LINK.thumbnail,
    ],
    [product?.id, product?.thumbnails?.length]
  );

  return (
    <React.Fragment>
      <Swiper
        pagination={{
          clickable: true,
        }}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        className="mySwiper2"
        style={{
          //@ts-ignore
          "--swiper-navigation-color": "#525252",
        }}
      >
        {images?.length > 0 &&
          images?.map((item: any, index: number) => (
            <SwiperSlide key={`main-slider-${index}`}>
              <Image
                width={800}
                height={800}
                src={item.img}
                alt={item.alt}
                className="w-100 h-100 img-fluid"
                loading={index > 0 ? "lazy" : "eager"}
                // priority={index === 0}
              />
            </SwiperSlide>
          ))}
      </Swiper>

      <Swiper
        onSwiper={setThumbsSwiper}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        className="mySwiper"
      >
        {thumbnails?.length > 0 &&
          thumbnails?.map((item: any, index: number) => (
            <SwiperSlide key={`thumb-slider-${index}`}>
              <Image
                width={200}
                height={200}
                src={item.img}
                alt={item.alt}
                className="w-100 h-100 img-fluid"
                loading={"lazy"}
              />
            </SwiperSlide>
          ))}
      </Swiper>
    </React.Fragment>
  );
};

export { ThumbSlider };
