import { TypeOptions } from "react-toastify";
import { ButtonConstantstype, RequestMethod, TSize } from "./types";

// Request method object
export const REQUEST_METHOD = Object.freeze({
  POST: "POST" as RequestMethod,
  GET: "GET" as RequestMethod,
  PUT: "PUT" as RequestMethod,
  DELETE: "DELETE" as RequestMethod,
});

// Request status object
export const STATUSES = Object.freeze({
  LOADING: "loading" as string,
  IDLE: "idle" as string,
  ERROR: "error" as string,
  TIME_DELAY: "time_delay" as string,
  NO_INTERNET: "no_internet" as string,
});

// Request Content type
export const REQUEST_CONTENT_TYPE = Object.freeze({
  APP_JSON: "application/json",
  FORM_DATA: "multipart/form-data",
});

// FORM CONSTANTS
export const FORM_CONSTANTS = Object.freeze({
  ERROR_PARENT: "div",
  ERROR: "text-danger mb-2",
  EMAIL: "email",
  PASSWORD: "password",
  TEXT: "text",
  NUMBER: "number",
  DATE: "date",
  TIME: "time",
  TEXTAREA: "textarea",
  CHECKBOX: "checkbox",
  RADIO: "radio",
  SAVE: "Save",
  SUBMIT: "Submit",
  DAYS_OF_WEEK: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  DEFAULT_CITY: [
    {
      value: 133024,
      label: "Mumbai",
    },
  ],
  DEFAULT_STATE: [
    {
      value: 4008,
      label: "Maharashtra",
    },
  ],
  DEFAULT_COUNTRY: [
    {
      value: 101,
      label: "India",
    },
  ],
  DEFAULT_PINCODE: 400001,
});

// GENERAL CONSTANTS
export const GENERAL_CONSTANTS = Object.freeze({
  DATE_FORMAT: "YYYY-MM-DD",
  COMPACT_DATE_FORMAT: "DD/MM/YY",
  TIME_FORMAT: "hh:mm",
  STRING: "string",
  EMPTY_STRING: "",
  EMPTY_ARRAY: [],
  CLEAR: "clear",
  ENTER: "Enter",
  BACK: "Back",
  LOADING: "Loading...",
  SEARCH: "Search ...",
  CONFIRM_MODAL_MESSAGE: "Are you sure you want to proceed?",
  PRIMARY: "primary",
  SECONDARY: "secondary",
  SUCCESS: "success",
  DANGER: "danger",
  WARNING: "warning",
  DARK: "dark",
  INFO: "info",
  TRUE: true,
  FALSE: false,
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  PERCENTAGE: "percentage",
  PERCENTAGE_SYMBOL: "%",
  FLAT: "flat",
  FLAT_SYMBOL: "₹",
  NA: "N/A",
  YES: "Yes",
  COMPLETED: "completed",
  PENDING: "pending",
  CANCELLLED: "cancelled",
  NO: "No",
  ALL: "all",
  STATUS_OPTIONS: [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ],
  YES_NO_OPTIONS: [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ],
  LARGE: "lg" as TSize,
  MEDIUM: "md" as TSize,
  SMALL: "sm" as TSize,
  EXTRA_SMALL: "xs" as TSize,
  EXTRA_LARGE: "xl" as TSize,
  EXTRA_EXTRA_LARGE: "xxl" as TSize,
  NAVIGATE: "navigate",
  PROCEED: "Proceed",
  CONFIRM_CHANGES: "Confirm Changes",
  APPLY: "Apply",
  PER_PAGE_LIMIT: 5,
});

// BUTTON CONSTANTS
export const BUTTON_CONSTANTS: ButtonConstantstype = Object.freeze({
  BUTTON: "button",
  SUBMIT: "submit",
  RESET: "reset",
  SAVE: "Save",
});

// STATIC CONSTANTS
export const STATIC_CONSTANTS = Object.freeze({
  LOCAL_PAGES: [
    "contact-us",
    // "festive-offers-1",
    // "festive-offers-2",
    // "festive-offers-3",
    "sitemap",
    "testimonials",
    "faqs",
    "our-stores",
    // "secure-shopping",
    // "festival-calendar",
    // "terms",
    // "shipping-policy",
    // "privacy-policy",
    // "disclaimer",
  ],
  GEO_COORDS: {
    "AVARYA GOREGAON WEST": {
      latitude: 19.163247600754104,
      longitude: 72.84570277769991,
    },
    "AVARYA SIKKA NAGAR": {
      latitude: 18.956229988182084,
      longitude: 72.82258616061644,
    },
    "AVARYA GOREGAON": {
      latitude: 19.173222155527924,
      longitude: 72.86837273745236,
    },
    "AVARYA WALKESHWAR": {
      latitude: 18.947631129725526,
      longitude: 72.79702191953996,
    },
    "AVARYA WORLI": {
      latitude: 19.002001869136336,
      longitude: 72.81434770851286,
    },
    "AVARYA CHEMBUR": {
      latitude: 19.060364864129177,
      longitude: 72.8983431381086,
    },
    "AVARYA LALBAUG": {
      latitude: 18.994443357940515,
      longitude: 72.83641347926483,
    },
    "AVARYA GHATKOPAR": {
      latitude: 19.09367704353232,
      longitude: 72.91311456780626,
    },
    "AVARYA BREACH CANDY": {
      latitude: 18.97359699858763,
      longitude: 72.8059100788314,
    },
    "AVARYA SANTACRUZ": {
      latitude: 19.081245746371767,
      longitude: 72.83582497981526,
    },
  } as Record<string, Record<"latitude" | "longitude", number>>,
  S3_BUCKET_LINK: "https://media.avarya.in",
  FSSAI_VERIFIED_LINK: {
    full: {
      img: "https://media.avarya.in/product/certificate/fssai-verified-full.webp",
      alt: `fssai-verified-full`,
    },
    thumbnail: {
      img: "https://media.avarya.in/product/certificate/fssai-verified-thumbnail.webp",
      alt: `fssai-verified-thumbnai`,
    },
  },
  HEADER_LOGO_LINK: "site-settings/icon/avarya-brand-logo-header.webp",
  FOOTER_LOGO_LINK: "site-settings/icon/avarya-brand-logo-footer.webp",
  MEGA_MENU_LINK: "site-settings/icon/mega-menu-banner.webp",
  GOOGLE_VERIFICATION_CODE: "ks5_RxGF6skRq5VbboH27CnaeKtHFqYf-unZiSYiv_I",
  LISTING_EXCLUDE: ["category", "sub_category", "wishlist"],
  DISABLED_BREADCRUMB: ["signin", "register"],
  ENV_CONSTANTS: {
    SECRET: process.env.NEXT_PUBLIC_SECRET || "avarya-secret",
    SITE_URL: process.env.NEXT_PUBLIC_SITE_URL,
    // SERVER_URL: process.env.NEXT_PUBLIC_LOCAL_SERVER_URL,
    SERVER_URL: process.env.NEXT_PUBLIC_SERVER_URL,
    RAZORPAY_ID: process.env.NEXT_PUBLIC_RAZORPAY_ID || "",
    RAZORPAY_KEY: process.env.RAZORPAY_KEY,
    RECAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || "",
  },

  STORE_ID: 4,
  INTERNATIONAL_SHIPPING: {
    location: "state",
    value: 4008,
  },
  TOKEN_CONSTANTS: {
    ACCESS_TOKEN: "access_token",
    REFRESH_TOKEN: "refresh_token",
    CUSTOMER_DETAILS: "customer_details",
    DISCOUNT_CODE: "discount_code",
  },
  RAZORPAY_CONSTANTS: {
    CHECKOUT_URL: "https://checkout.razorpay.com/v1/checkout.js",
    DEFAULT_CURRENCY: "INR",
    PAYMENT_FAILED_STATUS: "failed",
    PAYMENT_SUCCESS_STATUS: "success",
  },
  FOOTER_CONSTANTS: {
    CONTACT_NUMBER: "(+91) - 9152414230",
    CONTACT_LINK: `https://api.whatsapp.com/send?phone=919152414230`,
    MAIL: "info@avarya.in",
    MAIL_LINK: "mailto:info@avarya.in",
    ADDRESS: "Shop No 5 to 9, Avighna 9, Lalbaug, Mumbai - 400012",
    ADDRESS_LINK:
      "https://www.google.com/search?sca_esv=1671dfeb145c5278&tbs=lf:1,lf_ui:10&tbm=lcl&sxsrf=ADLYWIL9Vh6CTPvbJXOf-rpUhsFaRUS2tg:1722240120666&q=Avarya&rflfq=1&num=10&sa=X&ved=2ahUKEwi-xfC05MuHAxXn4zgGHdhcAcAQjGp6BAgnEAE&biw=1536&bih=745&dpr=1#rlfi=hd:;si:;mv:[[19.206521499999997,73.0439797],[18.9330049,72.7829876]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:10",
  },
});

export const LOCAL_STORAGE_CONSTANTS = Object.freeze({
  CART: "cart",
});

// API CONSTANTS
export const API_ROUTES = Object.freeze({
  LOGIN: "/account/signin",
  CHECKOUT: "/checkout",
  FB: "/fb/avaryaretail",
  INSTA: "/insta/avaryaretail",
  YT: "https://www.youtube.com/@avaryaretail",
});

// TOAST CONSTANTS
export const TOAST_CONSTANTS = Object.freeze({
  INFO: "info" as TypeOptions,
  SUCCESS: "success" as TypeOptions,
  ERROR: "error" as TypeOptions,
  WARNING: "warning" as TypeOptions,
  DEFAULT: "default" as TypeOptions,
});

// WIDGET CONSTANTS
export const WIDGET_CONSTANTS = Object.freeze({
  BANNER: "banner",
  LISTING: "item-listing",
  MULTIPLE_LISTING: "column-listing",
  DEALS: "promotional-section",
  STATIC: "rich-text-editor",
});

// LOGIN CONSTANTS
export const LOGIN_CONSTANTS = Object.freeze({
  CONTACT_NUMBER: {
    name: "contact_number",
    otp: "mobile_otp",
  },
  EMAIL: {
    name: "email",
    placeholder: "Enter your email* (For order updates)",
    otp: "email_otp",
  },
  EMAIL_OR_CONTACT: {
    name: "email_or_phone",
    placeholder: "Enter email or contact number*",
  },
  OTP: {
    name: "otp",
    placeholder: "Enter OTP*",
  },
  REQUEST_OTP: "Request OTP",
  RESEND_OTP: "Resend code",
});

// REGISTER CONSTANTS
export const REGISTER_CONSTANTS = Object.freeze({
  COUNTRY_CODE: {
    name: "country_code",
  },
  CONTACT_NUMBER: {
    name: "contact_number",
    placeholder: "Enter contact number*",
  },
  NAME: {
    name: "name",
    placeholder: "Enter name*",
  },
  EMAIL: {
    name: "email",
    placeholder: "Enter email*",
  },
});

// PROFILE CONSTANTS
export const PROFILE_CONSTANTs = Object.freeze({
  FIRST_NAME: {
    name: "first_name",
    label: "First Name",
    placeholder: "Enter First Name",
  },
  LAST_NAME: {
    name: "last_name",
    label: "Last Name",
    placeholder: "Enter Last Name",
  },
  EMAIL: {
    name: "email",
    label: "Email Address",
    placeholder: "Enter Email",
  },
  CONTACT_NUMBER: {
    placeholder: "Enter Mobile Number",
    label: "Mobile Number",
    name: "contact_number",
  },
});

// CART CONSTANTS
export const CART_CONSTANTS = Object.freeze({
  COLS: [
    {
      col: "Product",
      className: "custome-checkbox start",
      colSpan: 2,
    },
    {
      col: "Weight",
      className: "start",
    },
    {
      col: "Price",
      className: "start",
    },
    {
      col: "Qty",
      className: "start",
    },
    {
      col: "Amount",
      className: "start",
    },
  ],
});
