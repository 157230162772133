"use client";

import {
  SiteConfig,
  transformDataToBannerConfig,
  transformDataToListingConfig,
  transformDataToMultipleListingConfig,
  transformDataToProductDealsConfig,
  WIDGET_CONSTANTS,
} from "@/utils";
import { notFound, usePathname } from "next/navigation";

import parse from "html-react-parser";
import React, { MutableRefObject } from "react";
import {
  BannerWidget,
  Deals,
  ListingWidet,
  MultipleListing,
} from "./group-components";
import { setLoadingState, useAppDispatch } from "@/store";

const Widget = ({
  pageData,
  loadingRef,
  slug,
  nonce,
  site_config,
}: {
  slug: string[];
  nonce?: string | null;
  pageData?: any;
  loadingRef: MutableRefObject<HTMLDivElement | null>;
  site_config: SiteConfig;
}) => {
  const dispatch = useAppDispatch();

  const paths = usePathname();

  if (!pageData || (Array.isArray(pageData) && pageData.length === 0)) {
    dispatch(setLoadingState(false));
    return notFound();
  }

  const renderWidget = (widget: any, index: number) => {
    const commonProps = {
      widgetIndex: index,
      pageDetails: {
        display_name: pageData.display_name,
        short_summary: pageData.short_summary,
      },
    };

    const { widget_type } = widget;

    if (widget_type === WIDGET_CONSTANTS.BANNER) {
      return (
        <BannerWidget
          config={transformDataToBannerConfig(widget)}
          {...commonProps}
        />
      );
    }

    if (widget_type === WIDGET_CONSTANTS.LISTING) {
      let listingConfig = transformDataToListingConfig(widget);

      return (
        <ListingWidet
          site_config={site_config}
          nonce={nonce}
          slug={slug}
          config={listingConfig}
          type={widget.type}
          noOfWidget={pageData?.content?.length}
          {...commonProps}
        />
      );
    }

    if (widget_type === WIDGET_CONSTANTS.MULTIPLE_LISTING) {
      return (
        <MultipleListing
          config={transformDataToMultipleListingConfig(widget)}
          {...commonProps}
        />
      );
    }

    if (widget_type === WIDGET_CONSTANTS.DEALS) {
      return (
        <Deals
          config={transformDataToProductDealsConfig(widget)}
          {...commonProps}
        />
      );
    }

    if (widget_type === WIDGET_CONSTANTS.STATIC) {
      return parse(widget?.content?.[0]?.value);
    }

    dispatch(setLoadingState(false));

    return notFound();
  };

  return (
    <React.Fragment key={`${paths}-${pageData?.id}`}>
      {pageData.content.map((item: any, index: number) =>
        renderWidget(
          {
            page_id: pageData.id,
            id: item.id,
            widget_id: item.widget.id,
            widget_type: item.widget.widget_type,
            content: item.content,
            type: item.pages
              ? "static"
              : item.product_category_cms
              ? "category"
              : item.product_subcategory_cms
              ? "sub_category"
              : "product",
          },
          index
        )
      )}
      <div ref={loadingRef} />
    </React.Fragment>
  );
};

export { Widget };
