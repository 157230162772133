import {
  constructStructuredData,
  IFAQ,
  TProductDetails,
  IReview,
  SiteConfig,
  IStores,
} from "@/utils";
import Script from "next/script";
import React from "react";

const StructuredData = ({
  site_config,
  stores_data,
  faqs,
  productDetails,
  reviews,
  nonce,
  slug,
  type,
  products,
}: {
  site_config: SiteConfig;
  stores_data?: IStores[];
  faqs?: IFAQ[];
  productDetails?: TProductDetails;
  reviews?: IReview[];
  nonce: string | null;
  slug: string[];
  type: "Static" | "Product" | "Category" | "Sub Category";
  products?: TProductDetails[];
}) => {
  const structuredData = constructStructuredData({
    site_config,
    stores_data: stores_data ?? [],
    slug: slug,
    type: type,
    faqs: slug.includes("faqs") ? faqs : [],
    productDetails: productDetails,
    reviews: reviews,
    products: products,
  });

  return (
    <React.Fragment>
      {structuredData && structuredData?.length > 0
        ? structuredData?.map((data: any, index: number) => (
            <Script
              key={`${slug.join("/")}-${index}`}
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(data),
              }}
              nonce={nonce || ""}
              strategy="beforeInteractive"
            />
          ))
        : null}
    </React.Fragment>
  );
};

export { StructuredData };
