"use client";

import { BUTTON_CONSTANTS, SiteConfig } from "@/utils";
import { Button } from "./core-components";
import { useRouter } from "next/navigation";
import Image from "next/image";

const SiteUnderMaintainance = ({ siteData }: { siteData: SiteConfig }) => {
  const router = useRouter();

  return (
    <main className="main page-404">
      <div className="page-content pt-200 pb-260">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-md-12 m-auto text-center">
              <div className="logo">
                <Image
                  width={500}
                  height={500}
                  src={siteData?.site_footer_logo_link}
                  alt="avarya-logo"
                  className="hover-up w-100 h-100"
                />
              </div>

              <h1 className="display-2 mb-30">We’re working on the site!</h1>
              <p className="font-lg text-grey-700 mb-30">
                Our site is currently under maintenance as we work to improve
                your shopping experience. We’ll be back shortly with a refreshed
                look and even better features!
                <br />
              </p>
              <Button
                className="btn btn-default submit-auto-width font-xs hover-up"
                text="Refresh"
                type={BUTTON_CONSTANTS.BUTTON}
                onClick={() => router.refresh()}
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export { SiteUnderMaintainance };
