"use client";

import Image from "next/image";
import Link from "next/link";

import not_found from "@media/page/page-404.png";
import { useEffect } from "react";
import { setLoadingState, useAppDispatch } from "@/store";

const NotFound = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLoadingState(false));
  }, [window.location.href]);

  return (
    <div className="row">
      <div className="col-xl-8 col-lg-10 col-md-12 m-auto text-center">
        <p className="mb-20">
          <Image
            width={500}
            height={500}
            src={not_found}
            alt="not-found"
            className="hover-up w-100 h-100"
          />
        </p>
        <h1 className="display-2 mb-30">Whoops! Page Missing</h1>
        <p className="font-lg text-grey-700 mb-30">
          The page you’re looking for seems to have disappeared. It might have
          been moved or is no longer available.
          <br />
          Check out our
          <Link href="/">
            <span> Homepage </span>
          </Link>
          or explore our treats to find something you’ll love!
          {/* <Link href="/contact-us">
          <span> Contact us </span>
        </Link>
        about the problem */}
        </p>
        <Link
          className="btn btn-default submit-auto-width font-xs hover-up mt-30"
          href="/"
        >
          <i className="fi-rs-home ml-0 mr-5"></i> Back To Home Page
        </Link>
      </div>
    </div>
  );
};

export { NotFound };
